<template>
  <div>
    <button style="display: flex; background-color: white; align-items: center; margin-left:1rem; margin-top:4px; border: none" title="Regresar" v-on:click="comeback()">
      <box-icon type='solid' name='left-arrow-square' size="md"></box-icon>
    </button>
  </div>
  <div class="mt-3" style="display: flex; flex-direction: column; align-items: center">
    <h2>MÓDULO DE VERIFICACIÓN</h2>
    <div v-for="(student, i) in information" :key="i + 1" style="display: flex; flex-direction: column; align-items: center">
      <h6 style="width: 60%; text-align: justify;" v-show="student.status != 'exuniversitario'">Favor de llenar la solicitud para obtener la verificación. Recuerda que tu comprobante de estudios debe coincidir con las fechas registradas. Una vez validado por Mundo Universitario y, en caso de aprobarse, te aparecerá un símbolo de verificado.</h6>
      <h6 style="width: 60%; text-align: justify;" v-show="student.status === 'exuniversitario'" >Favor de llenar la solicitud para obtener una verificación. Y al final se le pedirá
        Ingrese sus documentos probatorios, en caso de validarse por mundo universitario, te
        aparecerá un símbolo de verificado.</h6>
    </div>
    <form @submit.prevent="sendVerification()" class="center mt-4" style="display: flex; flex-direction: column">
      <div class="col-md-12">
        <p style="font-weight: bolder">Nombre de la Institución Educativa</p>
        <div class="form-floating mb-3">
          <input type="text" v-model="state.instituteEducation" class="form-control" id="instituteEducation" placeholder="Nombre de la Institución Educativa" required />
          <label for="instituteEducation">Ingresa el nombre de tu Institución Educativa</label>
        </div>
      </div>
      <div class="col-md-12">
        <p style="font-weight: bolder">Carrera o estudios</p>
        <div class="form-floating mb-3">
          <input type="text" v-model="state.careerStudent" class="form-control" id="careerStudent" placeholder="Carrera o estudios cursando" required />
          <label for="careerStudent">Ingresa la Carrera o estudios</label>
        </div>
      </div>
      <div class="flex row" v-for="(student, i) in information" :key="i + 1">
        <p style="font-weight: bolder" v-show="student.status != 'exuniversitario'">¿De que periodo a que periodo abarca tu comprobante de estudios?</p>
        <p style="font-weight: bolder" v-show="student.status === 'exuniversitario'">Fechas que comprendieron sus estudios</p>
        <div class="col-md-3 mb-3">
          <label for="dateComprobanteInicio">Fecha Inicio</label>
          <input type="date" v-model="state.dateComprobanteInicio" class="form-control" id="dateComprobanteInicio" placeholder="Fecha inicio" required />
        </div>
        <div class="col-md-3 mb-3">
          <label for="dateComprobanteFin">Fecha Fin</label>
          <input type="date" v-model="state.dateComprobanteFin" class="form-control" id="dateComprobanteFin" placeholder="Fecha fin" required />
        </div>
      </div>
      <div class="col-md-12" v-for="(student, i) in information" :key="i + 1" v-show="student.status != 'exuniversitario'">
        <p style="font-weight: bolder">Fecha estimada de la terminación de tus estudios</p>
        <div class="col-md-3 mb-3">
          <label for="dateTermino">Fecha de la terminación</label>
          <input type="date" v-model="state.dateTermino" class="form-control" id="dateTermino" placeholder="Fecha Termino" />
        </div>
      </div>
      <div class="row align-items-start mt-4" v-for="(student, i) in information" :key="i + 1">
        <div class="col">
          <UploadPartials title="INE (AMBOS LADOS)" @data="changeCv" />
        </div>
        <div class="col" v-show="student.status === 'exuniversitario'">
          <UploadPartials title="CONSTANCIA DE TERMINACIÓN DE ESTUDIOS, TÍTULO O CÉDULA" @data="changeComprobante" />
        </div>
        <div class="col" v-show="student.status != 'exuniversitario'">
          <UploadPartials title="Constancia de Estudios Vigente" @data="changeComprobante" />
        </div>
      </div>
      <div class="mt-4 mb-5">
        <button class="btn-public btn btn-success" type="submit" :disabled="response.loading">
          <span>Solicitar Verificación</span>
          <div class="spinner-border spinner-border-sm float-end" role="status" v-show="response.loading">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { UserService } from "@/services";
import UploadPartials from "@/components/partials/upload.partials.vue";

export default {
  data: () => ({
    response: {
      loading: false,
    },
    id_student: "",
    state: {
      instituteEducation: "",
      dateComprobanteInicio: "",
      dateComprobanteFin: "",
      careerStudent: "",
      dateTermino: "",
    },
    filecv: "",
    filecomprobantes: "",
    information: [],
    file_name: null,
    file: null,
    document: null,
  }),
  components: {
    UploadPartials,
  },
  mounted() {
    this.extractionInformation();
    this.dataUser = JSON.parse(localStorage.getItem("user") || null);
    this.id_student = this.dataUser._id;
  },
  methods: {
    async extractionInformation() {
      await UserService.getStudent().then((response) => {
        this.information = response.data;
      });
    },
    changeComprobante(data) {
      this.filecomprobantes = data;
    },
    changeCv(data) {
      this.filecv = data;
      console.log(this.filecv);
    },
    async sendVerification() {
        try {
            this.response.loading = true;
            const form = {
                _id_student: this.id_student,
                ...this.state,
                filecomprobantes: this.filecomprobantes,
                filecv: this.filecv,
            };
            const resp = await UserService.verificationStudents(form);
            
            // Mostrar la alerta y esperar hasta que se cierre para redirigir
            this.$swal({
                position: "center",
                icon: "success",
                text: resp.data.message,  // Usa el mensaje recibido desde el backend
                showConfirmButton: false,
                timer: 2500,
            }).then(() => {
                // Aquí realizamos la redirección después de que la alerta se cierre
                this.comeback();
            });
            
            this.response.loading = false;

        } catch (error) {
            this.response.loading = false;
            this.$swal({
                position: "center",
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 4000,
            });
            return false;
        }
    },
    comeback() {
        this.$router.push('/profile');
    },

  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/about.scss";
</style>
